/* eslint-disable no-unused-vars */
import {
  requestAdd,
  requestDelete,
  requestRead,
  requestGetThread,
} from "@/api/ltwn";
import { notification } from "ant-design-vue";

const actions = {
  async handleGetThread({ commit }, token) {
    const { data } = await requestGetThread(token);
    // // console.log(data);
    // notification.open({
    //   message: "Result",
    //   description: JSON.stringify(data),
    // });
    return data;
  },

  async handleRead({ commit }, token) {
    const { data } = await requestRead(token);
    // console.log(data);
    return data;
  },

  async handleAdd({ commit }, parameters) {
    const { data } = await requestAdd(parameters);
    notification.open({
      message: "Result",
      description: JSON.stringify(data),
    });
    return data;
  },
  async handleDelete({ commit }, parameters) {
    const { data } = await requestDelete(parameters);
    // console.log(data);
    notification.open({
      message: "Result",
      description: JSON.stringify(data),
    });
    return data;
  },
};
export default { actions };
