import axios from "axios";

/**
 * @description axios初始化
 */
const instance = axios.create({
  baseURL: "https://www.mrliuys.cn/",
  timeout: 12000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export default instance;
