<!-- eslint-disable vue/no-unused-components -->
<template>
  <a-layout v-if="showLoading === false">
    <a-layout-header>
      <a-input
        style="width: 100px; margin-right: 4px"
        v-model:value="form.name"
        placeholder="Username"
      />
      <a-input
        style="width: 100px; margin-right: 4px"
        v-model:value="form.address"
        placeholder="Address"
      />
      <a-input
        style="width: 80px; margin-right: 4px"
        v-model:value="form.code"
        placeholder="Code"
      />
      <a-button class="editable-add-btn" @click="handleAdd"
        >Add</a-button
      ></a-layout-header
    >
    <a-layout-content class="login-container">
      <div class="login-container" style="padding: 0 8px">
        <a-table
          class="ant-table-scroll"
          bordered
          style="border-spacing: 4px 2px; border-collapse: separate"
          :columns="columns"
          :data-source="showData"
          :pagination="{ pageSize: 50 }"
          @change="handleTableChange"
        >
          <template #headerCell="{ column }">
            <template v-if="column.key === 'name'">
              <span>
                <smile-outlined />
                Name
              </span>
            </template>
          </template>

          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ index + 1 }}
            </template>
            <template v-else-if="column.key === 'name'">
              <a>
                {{ record.name }}
              </a>
            </template>
            <template v-else-if="column.key === 'action'">
              <span>
                <a @click="handleDelete(record)">Delete</a>
              </span>
            </template>
          </template>
        </a-table>
      </div></a-layout-content
    >
    <a-layout-footer>
      <a-button
        class="editable-add-btn"
        @click="handleThread"
        style="width: 100px; margin-right: 4px"
        >Thread</a-button
      >
      <a-button
        class="editable-add-btn"
        @click="handleLogout"
        style="width: 100px; margin-left: 4px"
        >Logout</a-button
      ></a-layout-footer
    >
  </a-layout>
  <a-spin tip="Loading..." v-if="showLoading === true">
    <a-alert message="处理数据中"></a-alert>
  </a-spin>
  <a-modal v-model:visible="visible" title="Alert" @ok="handleResultOk">
    <p>确定删除: {{ selectItem.name }} , {{ selectItem.address }}</p>
    <a-input
      style="width: 100px; margin-right: 10px"
      v-model:value="form.code"
      placeholder="Code"
    />
  </a-modal>

  <a-modal
    v-model:visible="threadVisible"
    title="Thread"
    @ok="handleThreadResultOk"
  >
    <a-list size="small" bordered :data-source="threadData">
      <template #renderItem="{ item }">
        <a-list-item>{{ item }}</a-list-item>
      </template>
    </a-list>
  </a-modal>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "LtwnView",
  components: {},
  data() {
    return {
      data: [],
      showData: [],
      columns: [],
      userToken: "",
      showLoading: false,
      visible: false,
      selectItem: {},
      form: {
        name: "",
        address: "",
        code: "",
      },
      threadData: [],
      threadVisible: false,
    };
  },
  mounted() {
    const pUserToken = localStorage.getItem("userToken");
    if (!pUserToken) {
      this.$router.push({ path: "/login" });
    } else {
      this.userToken = pUserToken;
      this.$nextTick(() => {
        this.handleRead();
      });
    }
  },

  methods: {
    ...mapActions({
      requestGetThread: "ltwn/handleGetThread",
      requestRead: "ltwn/handleRead",
      requestAdd: "ltwn/handleAdd",
      requestDelete: "ltwn/handleDelete",
    }),
    handleTableChange(filters, sorter) {
      // console.log(filters);

      if (!sorter["address"]) {
        this.showData = this.data;
        return;
      }
      const pShowDatas = [];

      for (let index = 0; index < this.data.length; index++) {
        const item = this.data[index];
        if (sorter["address"].includes(item["address"])) {
          pShowDatas.push(item);
        }
      }

      this.showData = pShowDatas;
    },
    async handleRead() {
      this.showLoading = true;
      const response = await this.requestRead({ token: this.userToken });
      this.handleResponse(response);
      this.showLoading = false;
    },
    async handleThread() {
      const response = await this.requestGetThread({ token: this.userToken });
      if (response["code"] === 0) {
        const data = String(response["data"]["data"]);
        console.log(data);
        this.threadData = response["data"]["data"].split("\n");
        console.log(this.threadData);
      }
      // console.log(response);
      this.threadVisible = true;
    },
    handleThreadResultOk() {
      this.threadVisible = false;
    },
    async handleAdd() {
      // this.handleRead();
      this.showLoading = true;
      const response = await this.requestAdd({
        token: this.userToken,
        name: this.form.name,
        address: this.form.address,
        code: this.form.code,
      });

      this.handleResponse(response);
      this.showLoading = false;
      this.form.name = "";
      this.form.address = "";
    },
    handleDelete(item) {
      // console.log(item);
      this.selectItem = item;
      this.visible = true;
    },
    async handleResultOk() {
      this.visible = false;
      this.showLoading = true;
      const response = await this.requestDelete({
        token: this.userToken,
        name: this.selectItem.name,
        address: this.selectItem.address,
        code: this.form.code,
      });

      this.handleResponse(response);

      this.showLoading = false;
    },
    async handleLogout() {
      localStorage.removeItem("userToken");
      this.$router.push({ path: "/login" });
    },

    handleResponse(response) {
      if (response["code"] !== 0) {
        return;
      }

      const listData = response["data"]["data"].reverse();

      const addressMap = {};
      const filters = [];

      for (let index = 0; index < listData.length; index++) {
        const element = listData[index];
        if (!addressMap[element["address"]]) {
          addressMap[element["address"]] = [element];
          filters.push({ text: element["address"], value: element["address"] });
        }
      }

      this.columns = [
        {
          title: "",
          key: "index",
          width: "50px",
        },
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Address",
          dataIndex: "address",
          filters: filters,
        },
        {
          title: "Action",
          key: "action",
        },
      ];
      this.data = listData;
      this.showData = this.data;
    },
  },
};
</script>
<style scoped>
.login-container {
  width: 100%;
  height: calc(100vh - 145px);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}
.table-container {
  padding: 20px;
  display: flex;
  overflow-x: auto; /* 设置水平滚动 */

  margin: 10px; /* 添加间距 */
}

.table-wrapper {
  flex-shrink: 0; /* 禁止缩小 */
  /* width: 30%;  */
  /* min-width: 400px;
  max-width: 400px; */
  border: 4px solid #e8e8e8;
  border-collapse: collapse; /* 合并边框 */
}

.horizontal-scroll-table {
  width: 100%;
}
.ant-layout-header {
  color: #fff;
  background: cornflowerblue;
  padding: 0px;
  /* flex-direction: column; */
  /* height: 300px; */
}
.ant-table-scroll {
  height: calc(100vh - 170px);
  overflow: auto scroll;
}
.ant-layout-footer {
  color: #fff;
  background: cornflowerblue;
}
</style>
