import request from "@/utils/request";

export async function requestGetThread(data) {
  return await request({
    url: "api/ltwn/getThread",
    method: "get",
    params: data,
  });
}

export async function requestRead(data) {
  return await request({
    url: "api/ltwn/read",
    method: "get",
    params: data,
  });
}

export async function requestAdd(data) {
  return await request({
    url: "api/ltwn/add",
    method: "get",
    params: data,
  });
}

export async function requestDelete(data) {
  return await request({
    url: "api/ltwn/delete",
    method: "get",
    params: data,
  });
}
