/* eslint-disable no-unused-vars */
import { requestLogin, requestTempToken } from "@/api/user";
import { notification } from "ant-design-vue";
//  ,
const actions = {
  /**
   * @description tempToken
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async tempToken({ commit }, userInfo) {
    const { data } = await requestTempToken(userInfo);
    // console.log(commit);
    // console.log(userInfo);
    notification.open({
      message: "Result",
      description: JSON.stringify(data),
    });
    return data;
  },

  /**
   * @description login
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit }, userInfo) {
    const { data } = await requestLogin(userInfo);
    // console.log(commit);
    // console.log(userInfo);
    notification.open({
      message: "Result",
      description: "Login Successed",
    });

    return data;
  },
};
export default { actions };
