<template>
  <div class="login-container">
    <div class="login-container-form">
      <div class="login-container-hello">Quick SMS</div>
      <!-- <div class="login-container-hello">{{ msg }}</div> -->
      <a-form :model="form" @submit="handleSubmit" @submit.prevent>
        <a-form-item>
          <a-input v-model:value="form.username" placeholder="Username">
            <template v-slot:prefix>
              <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            v-model:value="form.password"
            type="password"
            placeholder="Password"
          >
            <template v-slot:prefix>
              <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input v-model:value="form.code" placeholder="Code">
            <template v-slot:prefix>
              <CodeSandboxOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            :disabled="form.username === '' || form.password === ''"
          >
            Set
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  UserOutlined,
  LockOutlined,
  CodeSandboxOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "QuickSMS",
  components: {
    UserOutlined,
    LockOutlined,
    CodeSandboxOutlined,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        code: "",
      },
    };
  },
  mounted() {
    this.form.username = "";
    this.form.password = "";
    this.form.code = "";
  },
  methods: {
    ...mapActions({
      tempToken: "user/tempToken",
    }),
    async handleSubmit() {
      var response = await this.tempToken(this.form);

      if (response["code"] === 0) {
        // console.log("-------");
        // var token = response["data"]["token"]
        // var code = this.form.tokencode
        // var response11 = await this.tokenCode({"token":token,"code":code})
        // console.log(response11["data"]["user"])
      } else {
        // console.log("-------");
      }
      // await this.$router.push(this.handleRoute())
    },
  },
};
</script>
<style scoped lang="scss">
.login-container {
  width: 100%;
  height: 100vh;
  background-color: cornflowerblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &-form {
    margin: auto;
    width: 380px;
    height: auto;
    vertical-align: middle;
    background-color: #fff;
    padding: 4vh;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
  }
  &-hello {
    margin-bottom: 20px;
    font-size: 32px;
    color: #000;
  }
  &-title {
    margin-bottom: 30px;
    font-size: 20px;
    color: #000;
  }
  &-tips {
    position: fixed;
    bottom: 30px;
    width: 100%;
    height: 40px;
    color: rgba(255, 255, 255, 0.856);
    text-align: center;
  }
  .ant-col {
    width: 100%;
    padding: 0 10px 0 10px;
  }
  .ant-input {
    height: 35px;
  }
  .ant-btn {
    width: 100%;
    height: 45px;
    border-radius: 99px;
  }
}
</style>
