<template>
  <div class="login-container">
    <div class="login-container-form">
      <div class="login-container-hello">Login</div>
      <!-- <div class="login-container-title">1.账号</div> -->
      <a-form :model="form" @submit.prevent>
        <a-form-item>
          <a-input v-model:value="form.name" placeholder="Username">
            <template v-slot:prefix>
              <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input
            v-model:value="form.password"
            type="password"
            placeholder="Password"
          >
            <template v-slot:prefix>
              <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            v-on:click="handleLogin"
            :disabled="form.name === '' || form.code === ''"
          >
            Login
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  UserOutlined,
  LockOutlined,
  // CodeSandboxOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "LoginView",
  components: {
    UserOutlined,
    LockOutlined,
    // CodeSandboxOutlined,
  },
  data() {
    return {
      form: {
        name: "",
        password: "",
      },
    };
  },
  mounted() {
    this.form.name = localStorage.getItem("name");
    this.form.password = "";
  },
  methods: {
    ...mapActions({
      login: "user/login",
    }),
    async handleLogin() {
      var response = await this.login({
        username: this.form.name,
        password: this.form.password,
      });

      // localStorage.setItem("name", this.form.name);
      // console.log(response);

      if (response["code"] == 0) {
        localStorage.setItem("userInfo", response["data"]);
        localStorage.setItem("userToken", response["data"]["token"]);
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.login-container {
  width: 100%;
  height: 100vh;
  background-color: cornflowerblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &-form {
    margin: auto;
    width: 380px;
    height: auto;
    vertical-align: middle;
    background-color: #fff;
    padding: 2vh;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
  }
  &-hello {
    margin-bottom: 10px;
    font-size: 32px;
    color: #000;
  }

  &-title {
    text-align: left;
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    color: #000;
  }
  &-tips {
    position: fixed;
    bottom: 30px;
    width: 100%;
    height: 40px;
    color: rgba(255, 255, 255, 0.856);
    text-align: center;
  }
  .ant-col {
    width: 100%;
    padding: 0 10px 0 10px;
  }
  .ant-input {
    height: 35px;
  }

  .ant-btn {
    width: 100%;
    height: 45px;
    border-radius: 99px;
  }
}
</style>
