import { createRouter, createWebHashHistory } from "vue-router";
import QuickSMS from "../views/QuickSMS.vue";
import LtwnView from "../views/LtwnView.vue";
import HelloWorld from "../views/HelloWorld.vue";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";

const routes = [
  {
    path: "/",
    name: "HelloWorld",
    component: HelloWorld,
    hidden: true,
  },
  {
    path: "/home",
    name: "HomeView",
    component: HomeView,
    hidden: true,
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
    hidden: true,
  },
  {
    path: "/ltwn",
    name: "LtwnView",
    component: LtwnView,
    hidden: true,
  },
  {
    path: "/quick-sms",
    name: "QuickSMS",
    component: QuickSMS,
    hidden: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
