import request from "@/utils/request";

export async function requestTempToken(data) {
  return await request({
    url: "api/login/tempToken",
    method: "get",
    params: data,
  });
}

export async function requestLogin(data) {
  return await request({
    url: "api/login/login",
    method: "get",
    params: data,
  });
}
