<template>
  <div class="login-container">
    <div class="login-container-form">
      <img alt="Vue logo" src="@/assets/logo.png" />
      <div class="login-container-hello">Hello world!</div>
      <div class="login-container-title">mrliuys</div>
      <a-button type="primary" html-type="submit" v-on:click="handleJumpToYapi">
        yapi
      </a-button>
      <a-button type="primary" html-type="submit" v-on:click="handleJumpToLtwn">
        LTWN
      </a-button>
      <a-button
        type="primary"
        html-type="submit"
        v-on:click="handleJumpToQuickSMS"
      >
        Quick SMS
      </a-button>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {} from "@ant-design/icons-vue";

export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      //   nodei: logoImg,
      form: {},
    };
  },
  mounted() {},
  methods: {
    ...mapActions({}),

    handleJumpToYapi() {
      window.open("http://yapi.mrliuys.cn/", "_blank");
    },
    handleJumpToLtwn() {
      this.$router.push({ path: "/ltwn" });
    },

    handleJumpToQuickSMS() {
      this.$router.push({ path: "/quick-sms" });
    },
  },
};
</script>
<style scoped lang="scss">
.login-container {
  width: 100%;
  height: 100vh;
  background-color: cornflowerblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &-form {
    margin: auto;
    width: 350px;
    height: auto;
    vertical-align: middle;
    background-color: #fff;
    padding: 4vh;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
  }
  &-hello {
    // margin-bottom: 20px;
    font-size: 32px;
    color: #000;
  }
  &-title {
    text-align: center;
    font-size: 20px;
    color: #616060;
  }
  .ant-btn {
    width: 100%;
    height: 45px;
    border-radius: 99px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
